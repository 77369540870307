import React, { useContext } from 'react';

import { UserDataContext } from '../App';
import { useCtaHref } from '../hooks/utilHooks';
import Link from './Link';

type Props = {
  className?: string;
  text?: string;
  variant?: 'btnSm' | 'btnSmOutline';
  edu?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  id?: string;
};

const defaultProps = {
  text: 'Get started',
  variant: 'btnSm',
};

const CallToAction = (props: Props) => {
  const { userData } = useContext(UserDataContext);
  const href = useCtaHref(userData.user, props.edu);

  return (
    <Link
      className={props.className}
      variant={props.variant}
      href={href}
      useRouter={!userData.user}
      onClick={props.onClick}
      id={props.id}
    >
      {props.text}
    </Link>
  );
};

CallToAction.defaultProps = defaultProps;

export default CallToAction;
